import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Form from "../components/form"

import contactUs from "../images/contactUs.png"
import logo from "../images/smLogoblueW.png"

const ContactPage = props => {
  return (
    <Layout>
      <SEO title="Contact Skyrocket Marketing" />
      <div className="flex-row flex-wrap px-10 mb-12 md:flex">
        <div className="px-4 flex-1">
          <h1 className="text-5xl font-extrabold mb-1 xs:text-lg uppercase">
            Contact
          </h1>
          <img
            src={logo}
            alt="Skyrocket Digital Marketing"
            className={"h-16"}
          />
          <h4>Let's Get Started!</h4>
          <p>
            Send us a note and we will get back to you within
            <strong> 24 hours</strong>
          </p>
        </div>
        <div className="px-4 flex-1">
          <img src={contactUs} alt={"About Skyrocket Marketing"} />
        </div>
      </div>
      <div className="flex justify-center">
        <Form />
      </div>
    </Layout>
  )
}

export default ContactPage
