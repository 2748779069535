import React from "react"

export default function form(props) {
  return (
    <div className="w-100" style={{ width: "100%" }}>
      {
        <iframe
          src="https://skyrocketdm.typeform.com/to/tBqSTAW2"
          title="contact form"
          style={{ width: "80%", margin: "20px auto", height: "500px" }}
        />
      }
    </div>
  )
}
